// import educacaoIdades from "../images/materials/images/educacao-idades.jpg";
import vidaComecaDentro from "../images/materials/images/a-vida-comeca-dentro.jpg";
// import adventoNatal from "../images/materials/images/advento-natal.jpg";

export const storeData = [
  {
    title: "A Vida Começa Lá Dentro",
    image: vidaComecaDentro,
    link: "https://go.hotmart.com/O84790364N"
  }

  // {
  //   title: "Advento de Natal",
  //   image: adventoNatal,
  //   link: "https://go.hotmart.com/C88663645Y"
  // },


  // {
  //   title: "Educação Ao Longo Das Idades",
  //   image: educacaoIdades,
  //   link: "https://go.hotmart.com/Y84292936H?dp=1"
  // }
];