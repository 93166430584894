import * as React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.css';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { useState, useEffect } from 'react';

let slidesToShow = 5;
const PreviousBtn = (props) => {
  console.log(props);
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <ArrowBackIos style={{ color: '#953FE2', fontSize: '30px' }} />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  console.log(props);
  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div className={className} onClick={onClick}>
          <ArrowForwardIos style={{ color: '#953FE2', fontSize: '30px' }} />
        </div>
      )}
    </>
  );
};

const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 1,
  infinite: false,
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        // slidesToScroll: 2,
      },
    },
  ],
};

const MultiItemCarousel = ({multiData}) => {
  const [width, setWidth] = useState(null);
  const [slidesToShow, setSlidesToShow] = useState(5);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth);
      window.addEventListener('resize', updateWidth);
      return () => window.removeEventListener('resize', updateWidth);
    }
  }, []);

  useEffect(() => {
    if (width <= 426) {
      setSlidesToShow(1);
    } else if (width <= 769) {
      setSlidesToShow(3);
    } else if (width <= 1025) {
      setSlidesToShow(4);
    } else {
      setSlidesToShow(5);
    }
  }, [width]);

  return (
    <div style={{ margin: '30px' }} className='carousel'>
      <Slider {...carouselProperties}>
        {multiData.map((item, index) => (
          <Card key={index} item={item} />
        ))}
      </Slider>
    </div>
  );
};

const Card = ({ item }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={item.link}
        className="text-decoration-none"
      >
        <img
          className='multi__image'
          src={item.image}
          alt={item.title}
          style={{
            width: '100%',
            height: '370px',
            objectFit: 'cover',
            marginBottom: '10px',
            padding: '10px',
            cursor: 'pointer',
            borderRadius: '10px'
          }}
        />
      </a>
    </div>
  );
};

export default MultiItemCarousel;