import perigosoSubstantivos from "../images/materials/images/perigoso-substantivos.jpg";
import perigosoPalavrinhas from "../images/materials/images/perigoso-palavrinhas.jpg";

export const freeData = [
  {
    title: "Aprendendo sobre substantivos com Perigoso",
    image: perigosoSubstantivos,
    link: "https://drive.google.com/file/d/1NFAtdU3AxutPB0ZEMwzbSzkWZWePNmkl/view"
  },
  {
    title: "Aprendendo palavrinhas com Perigoso",
    image: perigosoPalavrinhas,
    link: "https://drive.google.com/file/d/1kJcDsPwuyFuZHav_1hgQTWP1ni7KHiM-/view"
  }
];